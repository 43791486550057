<template>
  <section id="workflow-users-list">
    <div class="inner-navbar">
      <el-row type="flex" align="middle">
        <el-col :span="24">
          <div class="icon-text pl-1">
            <h3 class="table-head-text" v-if="getFormBuilder">{{ getFormBuilder.name }}</h3>
          </div>
          <div class="icon-block d-flex align-center">
            <div class="icon-text pl-1" v-if="getFormBuilder">
              <div v-html="getFormBuilder.description"></div>
            </div>
          </div>
        </el-col>
      </el-row>

      <el-row :gutter="12" v-if="getFormBuilder && getFormBuilder.settings && getFormBuilder.settings.limit_total_submissions">
        <el-col :span="8">
          <el-card shadow="always">
            Total Allowed - {{ getFormBuilder.settings.no_responses_limited }}
          </el-card>
        </el-col>
        <el-col :span="8">
          <el-card shadow="always">
            Total Submited - {{ allWorkflows.length }}</el-card
          >
        </el-col>
        <el-col :span="8">
          <el-card shadow="always">
            Pending -
            {{ getPendingFormbuildersCount }}
          </el-card>
        </el-col>
      </el-row>

      <el-row
        :gutter="12"
        v-if="
          getFormBuilder &&
          getFormBuilder.settings &&
          getFormBuilder.settings.limit_total_submissions &&
          getFormBuilder.settings.no_responses_limited == allWorkflows.length
        "
      >
        <el-col :span="24">
          <el-alert
            title="Workflow status"
            type="success"
            description="You have finish all submissions for worflow"
            show-icon
            closable
          >
          </el-alert>
        </el-col>
      </el-row>

      <el-row
        :gutter="12"
        v-if="
          getFormBuilder &&
          getFormBuilder.settigns &&
          getFormBuilder.settings.limit_total_submissions &&
          getFormBuilder.settings.no_total_submissions_limited ==
            getGuestUserFormbuilderSubmissions
        "
      >
        <el-col :span="24">
          <el-alert
            title="Workflow Completed"
            type="success"
            description="Workflow reach our maximum submissions"
            show-icon
            closable
          >
          </el-alert>
        </el-col>
      </el-row>

      <el-col :span="24" v-if="checkWorkflowAllowed()">
        <div class="right-block float-right">
          <el-button
            class="right-block float-right add-buttons mt-0 button-uppercase"
            @click="goToNewformbuilderData"
          >
            <i class="el-icon-circle-plus-outline pr-1"></i>
            Submit New Data
          </el-button>
        </div>
      </el-col>
      <hr />
    </div>
    <div class="inner-navbar-content top">
      <div class="vue-data-table-default">
        <el-table :data="allWorkflows" v-loading="loading">
          <el-table-column label="S.NO" fixed="left">
            <template slot-scope="scope"> {{ scope.$index + 1 }} </template>
          </el-table-column>

          <el-table-column width="50">
            <template>
              <div>
                <img src="@/assets/img/avatar-2.svg" alt="avatar" width="30" />
              </div>
            </template>
          </el-table-column>

          <el-table-column
            v-if="hasIntervals"
            label="Submitted For"
            min-width="150"
          >
            <template slot-scope="scope">
              <div>
                <span class="black-coral sm-80" v-if="scope.row.time_interval">
                  {{
                    scope.row.time_interval
                      | moment(fequencyDateMap[intervalType])
                  }}
                </span>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="Submitted" min-width="150">
            <template slot-scope="scope">
              <div>
                <span
                  v-if="scope.row.sent_at"
                  class="d-block yale-blue sm-80 line-height-1"
                  >Submitted On</span
                >
                <span class="black-coral sm-80" v-if="scope.row.sent_at">
                  {{ scope.row.sent_at | moment("MM-DD-YYYY HH:mm") }}
                </span>

                <span
                  v-if="!scope.row.sent_at"
                  class="d-block yale-blue sm-80 line-height-1"
                  >Not Send</span
                >
              </div>
            </template>
          </el-table-column>

          <el-table-column label="Completion Status" width="250">
            <template slot-scope="scope">
              <div class="warning" v-if="scope.row.formbuilder_data_status == 'PENDING' ||
                  scope.row.formbuilder_data_status == 'SENT'">
                <i class="el-icon-refresh"></i>
                <span class="d-inline-block pl-10">
                  {{
                    scope.row.formbuilder_data_status == "PENDING" ||
                    scope.row.formbuilder_data_status == "SENT"
                    ? "Pending"
                    : ""
                  }}
                </span>
              </div>

              <div class="success" v-else-if="scope.row.formbuilder_data_status == 'COMPLETED'">
                <i class="el-icon-success"></i>
                <span class="d-inline-block pl-10">
                  {{
                    scope.row.formbuilder_data_status == "COMPLETED"
                    ? "Completed"
                    : ""
                  }}
                </span>
              </div>

              <div class="expired-div" v-else-if="scope.row.formbuilder_data_status == 'EXPIRED'">
                <i class="el-icon-error"></i>
                <span class="d-inline-block pl-10">
                  {{
                    scope.row.formbuilder_data_status == "EXPIRED"
                    ? "Expired"
                    : ""
                  }}
                </span>
              </div>

              <div v-else>
                 Pending
              </div>
            </template>
          </el-table-column>

          <el-table-column prop="action" min-width="100px">
            <template slot-scope="scope">
              <el-dropdown>
                <span class="el-dropdown-link">
                  Actions
                  <i class="el-icon-caret-bottom el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <a
                    @click="gotoFormbuilderData(scope.row, 'view')"
                    style="border: none"
                    ><el-dropdown-item
                      icon="el-icon-view"
                      command="edit"
                      >View</el-dropdown-item
                    ></a
                  >
                  <a
                    @click="gotoFormbuilderData(scope.row, 'edit')"
                    v-if="hasEditAccess"
                    style="border: none"
                    ><el-dropdown-item
                      icon="el-icon-edit-outline"
                      command="edit"
                      >Edit</el-dropdown-item
                    ></a
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <workflowUserSchedulesDialog
      v-if="showIntervalDailogVisible"
      :workflowDetails="workflowdDetails"
      @AddSchedule="addUserScheduledWorkflow"
      :completedIntervals="completed_intervals"
    >
    </workflowUserSchedulesDialog>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { bus } from "../../main";
import workflowUserSchedulesDialog from "../widgets/workflowUserSchedulesDialog.vue";
export default {
  components: {
    workflowUserSchedulesDialog,
  },
  data() {
    return {
      loading: false,
      allWorkflows: [],
      data: [],
      total_allowed: 0,
      total_filled: 0,
      pending: 0,
      showIntervalDailogVisible: false,
      workflowdDetails: {},
      hasIntervals: [],
      completed_intervals: [],
      intervalType: "",
      fequencyDateMap: {
        DAILY: "YYYY-DD-MM",
        WEEKLY: "YYYY-DD-MM",
        MONTHLY: "YYYY-DD-MM",
        YEARLY: "YYYY-DD-MM",
        HOURLY: "YYYY-DD-MM HH:mm",
      },
    };
  },
  mounted() {
    this.getNecessaryInfo();
  },

  created() {},
  beforeDestroy() {
    bus.$off("next-step");
  },
  computed: {
    ...mapGetters("formbuilderData", [
      "getGuestUserFormbuildersList",
      "getGuestUserFormbuilderSubmissions",
    ]),
    ...mapGetters("auth", ["getAuthenticatedUser"]),
    ...mapGetters("formBuilders", [
      "getFormBuilderAddStatus",
      "getFormBuilder",
      "getPreviewData",
    ]),
    getIndexFromStep() {
      return this.step - 1;
    },
    getFlowName() {
      return "Worflow name here";
    },
    hasEditAccess() {
      return (
        this.getFormBuilder &&
        this.getFormBuilder.settings &&
        this.getFormBuilder.settings.edit_responses
      );
    },
    getUserEmail() {
      return (formbuilderData) => {
        formbuilderData.workflow_user_id =
          formbuilderData.workflow_user_id.email;
      };
    },
    getPendingFormbuildersCount() {
      return (
        this.allWorkflows.filter(
          (e) =>
            e.formbuilder_data_status == "PENDING" ||
            e.formbuilder_data_status == "SENT"
        ) || []
      ).length;
    },
  },
  methods: {
    async getNecessaryInfo() {
      this.loading = true;
      let params = {
        formbuilder_details_id: this.$route.params.formbuilder_details_id,
        user_id: this.getAuthenticatedUser._id,
      };
      await this.$store.dispatch("formBuilders/fetchFormBuilder", {
        id: this.$route.params.formbuilder_details_id,
        include_template_details: true,
      });

      this.workflowdDetails = { ...this.getFormBuilder };
      this.hasIntervals =
        this.workflowdDetails &&
        this.workflowdDetails.settings &&
        this.workflowdDetails.settings.interval_settings &&
        this.workflowdDetails.settings.interval_settings.status;

      this.intervalType =
        this.workflowdDetails &&
        this.workflowdDetails.settings &&
        this.workflowdDetails.settings.interval_settings
          ? this.workflowdDetails.settings.interval_settings.type
          : null;

      await this.$store.dispatch(
        "formbuilderData/fetchGuestUserFormbuildersData",
        params
      );

      this.allWorkflows = [...this.getGuestUserFormbuildersList];
      this.data = this.allWorkflows;

      this.completed_intervals = this.data.map((e) => e.time_interval);
      this.loading = false;
    },

    addUserScheduledWorkflow(intervalDateTime = null) {
      let routerData = this.$router.resolve({
        name: "GuestWorkFlowExecute",
        params: this.$route.params,
        query: { ...this.$route.query, interval_date: intervalDateTime },
      });

      window.location.href = routerData.href;
      this.showIntervalDailogVisible = false;
    },

    checkWorkflowAllowed() {
      // return (
      //   (!this.getFormBuilder.settings.limit_responses ||
      //     this.getFormBuilder.settings.no_responses_limited !=
      //       this.allWorkflows.length) &&
      //   (!this.getFormBuilder.settings.limit_total_submissions ||
      //     this.getFormBuilder.settings.no_total_submissions_limited !=
      //       this.getGuestUserFormbuilderSubmissions)
      // );
      return false;
    },

    gotoFormbuilderData(data, action) {
      this.$router.push({
        name:
          action == "edit"
            ? "GuestFormbuilderExecute"
            : "GuestFormbuilderView",
        params: {
          formbuilder_details_id: this.$route.params.formbuilder_details_id,
        },
        query: {
          formbuilder_data_id: data._id,
          stepId: 0
        },
      });
    },
    goToNewformbuilderData() {
      this.showIntervalDailogVisible = false;
      if (
        this.workflowdDetails.settings &&
        this.workflowdDetails.settings.interval_settings &&
        this.workflowdDetails.settings.interval_settings.status
      ) {
        this.showIntervalDailogVisible = true;
      } else {
        let { ...params } = this.$route.params;

        this.$router.push({
          name: "GuestWorkFlowExecute",
          params: params,
          query: this.$route.query,
        });
      }
    },
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.el-table .cell {
    word-break: break-word;
}
.el-table .td{
  word-wrap: break-word;
}

.workflow-form-execute {
  
  margin-top: 0px !important;
  margin-right: 0px !important;
  .main-content-block {
    padding: 2em;
    background-color: #ffffff;

    ::v-deep .preview-template-view {
      .preview-form-header {
        margin-bottom: 1.5em !important;
      }
      .footer-action-block {
        .el-button {
          margin: 0em 0 0 auto;
        }
      }
      .form-group {
        label {
          color: #5e6d82 !important;
        }
        p {
          margin-top: 0px;
        }
      }
    }
  }
  .sidebar-navigation {
    position: sticky;
    top: 100px;
    .page-title {
      display: flex;
      align-items: center;
      font-weight: 600;
      margin-bottom: 1em;
      .scrolling-text {
        position: relative;
        width: 240px;
        min-height: 25px;
        overflow: hidden;
        line-height: 1.6;
        padding: 0px;
        &:after {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          width: 20px;
          background-image: linear-gradient(
            to right,
            #f8f9fa00,
            #f8f9fa05,
            #f8f9fa
          );
        }
        span {
          position: absolute;
          white-space: nowrap;
          transform: translateX(0);
          transition: 1.5s;
        }
        &:hover span {
          transform: translateX(calc(250px - 110%));
        }
      }
      .el-button {
        margin-right: 0.5em;
        padding: 4px;
        i {
          font-size: 1.1em;
        }
      }
    }
    .templates-list {
      margin-top: 2em;
      .nav-link {
        a {
          position: relative;
          color: #999a9d;
          display: block;
          padding: 1em 1em 1em 2em;
          margin-bottom: 0.5em;
          border-radius: 3px 0 0 3px;
          &::after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 10px;
            width: 10px;
            background-image: url("../../assets/img/icons/hover-dots.svg");
            background-size: 7px;
            background-repeat: no-repeat;
            background-position: center;
            opacity: 0;
            transition: 0.3s all ease-in-out;
            -webkit-transition: 0.3s all ease-in-out;
          }
          &:hover:after {
            opacity: 1;
          }
          &:hover {
            color: #1b487e;
          }
          .step-edit,
          .step-unfill,
          .step-default,
          .step-success {
            float: right;
            font-size: 1.25em;
            padding-top: 3px;
          }
          .step-success {
            color: rgb(103, 206, 0);
          }

          .step-unfill {
            color: rgb(214, 214, 52);
          }

          .step-edit {
            color: blue;
          }

          .step-default {
            color: #999999;
          }
        }
        .router-link-active {
          color: #1b487e;
          background-color: #ffffff;
          box-shadow: -5px 5px 10px #f9f9f9;
          &::after {
            opacity: 1;
          }
          .step-success {
            color: rgb(103, 206, 0);
          }
        }
      }
    }
  }
}
.expired-div {
  color: #dc3545;
}
</style>
